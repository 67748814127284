import React from "react";


export default function Intro(props){


    return(
        <div className="intro">
            <h1 className="intro--title">Quizzical</h1>
            <p className="intro--desc">this website is build by coding.zone22
                ;click the button to start the quiz
            </p>

            <button className="questions--button" onClick={props.handleClick}>Start quiz</button>
        </div>
    )
}