import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTiktok } from '@fortawesome/free-brands-svg-icons'




export default function Questions(props){

    return(
        <div className="questions">
            <h1 className="title">This Website is build by <span className="channel">Coding.zone22</span> </h1>
            {props.questionsElements}
            <div className="footer">
            {props.endQuiz && <p className="score--text">you scored {props.correctQuestionsNumb}/5 correct answers</p>}
            <div className="buttons">
            <button className="questions--button" onClick={props.handleChecked}>{props.endQuiz ? "Play again" :"Check answers"}</button>
            {props.endQuiz && <button onClick={props.newQuestions} className="questions--button">New Questions</button>}
            </div>

            </div>

        </div>

    )
}