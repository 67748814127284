import React from "react";
import Questions from './components/Questions'
import Intro from "./components/Intro";
import he from 'he'
import {nanoid} from 'nanoid'



export default function App(){
  const[intro,setIntro] = React.useState(true)
  const[questions,setQuestions] = React.useState([])
  const[endQuiz,setEndQuiz] = React.useState(false)




  React.useEffect(()=>{
      fetch("https://opentdb.com/api.php?amount=5&category=18&difficulty=easy&type=multiple")
      .then(res => res.json())
      .then(data => setQuestions(data.results.map(question =>{
        const choicesArray = []

        const randomNumber = Math.floor(Math.random()*4)
        choicesArray.push(...question.incorrect_answers)
        choicesArray.splice(randomNumber,0,question.correct_answer)
        return {...question,selectedChoice:"",choicesArray:choicesArray,correct:false}
      })))
  },[])


  function newQuestions(){
    window.location.reload();
  }

  function handleChecked(){
    setEndQuiz((prevEndQuiz)=>!prevEndQuiz)

  }


  function handleSelected(choice,questionIndex){
    setQuestions((prevQuestions)=>{
      return prevQuestions.map((question)=>{
        if(questions.indexOf(question) === questionIndex){
          return {
            ...question, selectedChoice:choice
          }
          
        }
        else{
          return {
            ...question
          }
        }
      })
    })
  }

  function correct(choice,questionIndex){
    setQuestions((prevQuestions)=>{
      return prevQuestions.map((question,index)=>{
        if(index === questionIndex && choice === question.correct_answer){
          return{
            ...question,correct:true
          }
        }
        else if(index === questionIndex && choice !== question.correct_answer){
          return{
            ...question,correct:false
          }
        }
        else{
          return{
            ...question
          }
        }
      })
    })
  }

  //endQuiz && questions[index].correct === false  && questions[index].choicesArray[0] === questions[index].selectedChoice ? "incorrect" : ""


  function chooseStyle(choice,questionIndex){
 
    if(endQuiz && questions[questionIndex].correct && questions[questionIndex].correct_answer === choice){
      return{
        backgroundColor:"#94D7A2"
      }
    }

  }


  function correctQuestionsNumb(){
    let total = 0;
    questions.map((question)=>{
      if(question.correct === true){
        total +=1
      }
    })
    return total
  }
const questionsElements = questions.map((item,index)=>{

    let firstChoiceId = nanoid();
    let secondChoiceId = nanoid();
    let thirdChoiceId = nanoid();
    let fourtChoiceId = nanoid()
    

    return(
    <div id={index} key={index} className="question">
        <h2 className="question--text">{he.decode(item.question)}</h2>

          <div className="choices" >
            <div>
            <input className={endQuiz ? "incorrect--input" : "choice--input"} id={firstChoiceId} type="radio" name={item.question} value={questions[index].choicesArray[0]}></input>
            <label className={endQuiz ? "incorrect" : "choice"} style={chooseStyle(questions[index].choicesArray[0],index)} for={firstChoiceId}  onClick={()=>{
              handleSelected(questions[index].choicesArray[0],index)
              correct(questions[index].choicesArray[0],index)
              }} >{he.decode(questions[index].choicesArray[0])}</label>
            </div>

            <div>
            <input className={endQuiz ? "incorrect--input" : "choice--input"} id={secondChoiceId} type="radio" name={item.question}  value={questions[index].choicesArray[1]}></input>
            <label className={endQuiz ? "incorrect" : "choice"}  style={chooseStyle(questions[index].choicesArray[1],index)} for={secondChoiceId} onClick={()=>{
              handleSelected(questions[index].choicesArray[1],index)
              correct(questions[index].choicesArray[1],index)
            }} >{he.decode(questions[index].choicesArray[1])}</label>
            </div>

            <div>
            <input className={endQuiz ? "incorrect--input" : "choice--input"} id={thirdChoiceId} type="radio" name={item.question}  value={questions[index].choicesArray[2]}></input>
            <label className={endQuiz ? "incorrect" : "choice"} style={chooseStyle(questions[index].choicesArray[2],index)} for={thirdChoiceId} onClick={()=>{
              handleSelected(questions[index].choicesArray[2],index)
              correct(questions[index].choicesArray[2],index)
            }} >{he.decode(questions[index].choicesArray[2])}</label>
            </div>

            <div>
            <input className={endQuiz ? "incorrect--input" : "choice--input"} id={fourtChoiceId} type="radio" name={item.question}  value={questions[index].choicesArray[3]}></input>
            <label className={endQuiz ? "incorrect" : "choice"}  style={chooseStyle(questions[index].choicesArray[3],index)} for={fourtChoiceId} onClick={()=>{
              handleSelected(index)
              correct(questions[index].choicesArray[3],index)
              }}>{he.decode(questions[index].choicesArray[3])}</label>
            </div>
            
            </div>
        <hr/>
    </div>
)})



  function handleIntro(){
    setIntro((prevIntro)=> !prevIntro)
  }

  return(
    <div className="container">
      
      {intro && <Intro handleClick={handleIntro}/>}
      {!intro && <Questions endQuiz={endQuiz} correctQuestionsNumb={correctQuestionsNumb()} handleChecked={handleChecked} newQuestions={newQuestions} questionsElements={questionsElements}/>}

    </div>
  )
}